/*!
 * Start Bootstrap - SB Admin v5.0.1 (https://startbootstrap.com/template-overviews/sb-admin)
 * Copyright 2013-2018 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-sb-admin/blob/master/LICENSE)
 */

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
  font-family: "Segoe UI", "Helvetica Neue", "Apple Color Emoji", "Segoe UI Emoji", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgb(32, 31, 31);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.user-info-tooltip .tooltip-inner {
  background-color: #27354B !important;
}

.user-info-tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.user-info-tooltip.bs-tooltip-top .arrow::before {
  margin-left: -3px !important;
  content: "" !important;
  border-width: 5px 5px 0 !important;
  border-top-color: #27354B !important;
  color: #27354B;
}
#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#wrapper #content-wrapper {
  overflow-x: hidden;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 80px;
  background-color: #f3f5f7; /* #F3F5F7 */
}

body.fixed-nav #content-wrapper {
  margin-top: 56px;
  padding-left: 90px;
}

body.fixed-nav.sidebar-toggled #content-wrapper {
  padding-left: 0;
}

@media (min-width: 768px) {
  body.fixed-nav #content-wrapper {
    padding-left: 225px;
  }
  body.fixed-nav.sidebar-toggled #content-wrapper {
    padding-left: 90px;
  }
}

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  background: rgba(52, 58, 64, 0.5);
  line-height: 46px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
  color: white;
}

.scroll-to-top:hover {
  background: #343a40;
}

.scroll-to-top i {
  font-weight: 800;
}

.smaller {
  font-size: 0.7rem;
}

.o-hidden {
  overflow: hidden !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.navbar-nav .form-inline .input-group {
  width: 100%;
}

.navbar-nav .nav-item.active .nav-link {
  color: #6bc4e8;
}

.navbar-nav .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}

.navbar-nav .nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f107";
}

.navbar-nav .nav-item.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}

.navbar-nav .nav-item .nav-link:focus {
  outline: none;
}

.navbar-nav .nav-item .nav-link .badge {
  position: absolute;
  margin-left: 0.75rem;
  top: 0.3rem;
  font-weight: 400;
  font-size: 0.5rem;
}

@media (min-width: 768px) {
  .navbar-nav .form-inline .input-group {
    width: auto;
  }
}

.sidebar {
  width: 90px !important;
  background-color: #27354B;
  min-height: calc(100vh - 56px);
}

.sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}

.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 90px;
}

.sidebar .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}

.sidebar .nav-item .nav-link {
  color: #fff;
}

.sidebar .nav-item .nav-link:active,
.sidebar .nav-item .nav-link:focus,
.sidebar .nav-item .nav-link:hover {
  color: #27354B;
  background-color: #6bc4e8;
}

.sidebar .nav-item:active,
.sidebar .nav-item:hover {
  background-color: #6bc4e8;
}

.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}

@media (min-width: 768px) {
  .sidebar {
    width: 225px !important;
  }
  .sidebar .nav-item .nav-link {
    display: block;
    text-align: left;
    padding: 1rem;
    height: 50px;
    width: 225px;
  }
  .sidebar .nav-item .nav-link span {
    font-size: 1rem;
    display: inline;
  }
  .sidebar .nav-item .dropdown-menu {
    position: static !important;
    margin: 0 1rem;
    top: 0;
  }
  .sidebar .nav-item.dropdown .dropdown-toggle::after {
    display: block;
  }
  .sidebar.toggled {
    overflow: visible;
    width: 90px !important;
  }
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 90px;
  }
  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }
  .sidebar.toggled .nav-item .dropdown-menu {
    position: absolute !important;
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(90px + 0.5rem) !important;
    margin: 0;
  }
  .sidebar.toggled .nav-item .dropdown-menu.dropup {
    bottom: 0;
    top: auto !important;
  }
  .sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
    display: none;
  }
}

.sidebar.fixed-top {
  top: 56px;
  height: calc(100vh - 56px);
  overflow-y: auto;
}

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -1.25rem;
  right: -1rem;
  opacity: 0.4;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
  }
}

@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

.card-login {
  max-width: 25rem;
}

.card-register {
  max-width: 40rem;
}

.form-label-group {
  position: relative;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

footer.sticky-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 90px);
  height: 50px;
  background-color: #e9ecef;
}

footer.sticky-footer .copyright {
  line-height: 1;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  footer.sticky-footer {
    width: calc(100% - 225px);
  }
}

body.sidebar-toggled footer.sticky-footer {
  width: 100%;
}

@media (min-width: 768px) {
  body.sidebar-toggled footer.sticky-footer {
    width: calc(100% - 90px);
  }
}

/* Custom CSS for PS Nav and Default */

.slack {
  box–sizing: border–box;
  color: #2c2d30;
  display: inline;
  font–family: NotoSansJP, Slack-Lato, appleLogo, sans-serif;
  line-height: 22px;
  font–size: 15px;
  font–variant–ligatures: common-ligatures;
  height: auto;
  text–size–adjust: 100%;
  user–select: auto;
  width: auto;
  word–wrap: break–word;
  font-weight: bold;
  padding-left: 10px;
}

.vl {
  border-left: 4px solid #F5F5F5;
  height: auto;
  margin-left: 40px;
}

.disc-box {
  background-color: #6bc4e8;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
}

.social-box {
  background-color: none;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
}

.slack-box {
  min-height: 350px;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 12px;
}

.navbar {
  min-height: 70px;
}

.social-form {
  border: none;
  background-color: #F3F5F7 !important;
  font-family: "roboto", sans-serif !important;
  font-size: 16px !important;
}

.form-margin {
  margin-bottom: 20px;
}

.input:focus {
  background-color: yellow;
}

.social-head {
  font-family: "roboto", sans-serif;
  font-size: 18px;
  color: #2e3e58;
  font-weight: 400;
  vertical-align: middle;
  padding-left: 10px !important;
}

.section-head {
  font-family: "roboto", sans-serif !important;
  font-size: 18px !important;
  color: #fff !important;
  font-weight: 400 !important;
  vertical-align: middle !important;
  padding-left: 10px !important;
}

.social-hr {
  margin-bottom: 0.25rem;
}

.card {
  margin-bottom: 20px;
  border: none;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.preview-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.5rem;
}

.card-outline-inverse .card-header {
  background: #354765;
  border-color: #354765;
}

.form-group {
  margin-bottom: 0.25rem;
}

textarea.form-control {
  height: auto;
  resize: none;
}

.cta-button {
  font-family: "roboto", sans-serif;
  font-size: 16px;
  font-weight: medium;
  text-transform: uppercase;
  width: 125px;
  margin: 3px;
}

.btn-post, .btn-yes {
  background: #58CE87;
  color: #ffffff;
}

.btn-schedule {
  background: #a18ee7;
  color: #ffffff;
}

.btn-delete {
  background: #767778;
  color: #ffffff;
}

.btn:hover {
  opacity: 0.75;
}

.btn-slack {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid rgba(160,160,162,.5);
  color: #2c2d30;
  max-width: 220px;
  font-family: NotoSansJP,Slack-Lato,appleLogo,sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  padding: 5px;
  margin: 10px 1px 1px 0;
  text-shadow: none;
}

.btn-tw {
  color: #00acee;
}

.btn-fb {
  color: #3b5998;
}

.btn-li {
  color: #4875b4;
}

.slack-preview {
  font-family: NotoSansJP, Slack-Lato, appleLogo, sans-serif;
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 300;
  color: #1d1c1d;
  line-height: 1.4;
  width: 100%;
}

.top-rounded {
  border-radius: 12px 12px 0 0 !important;
}

.slack-app {
  border-radius: 2px;
  font-size: 10px;
  padding: 1px 3px;
  color: #717274;
  background-color: #e8e8e8;
  display: inline-block;
  height: 14px;
  line-height: 1.25;
  font-weight: 700;
  vertical-align: 0.125rem;
}

.fa-4x {
  font-size: 1.4em;
  padding-right: 5px;
}

.greetings {
  font-family: "roboto", sans-serif !important;
}

.nav-menu-item {
  font-family: "roboto", sans-serif !important;
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #626868;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  text-decoration: none !important;
  border: 0;
}

.nav-menu-item:hover {
  color: #6bc4e8;
}

.nav-menu-item-co {
  font-family: "roboto", sans-serif !important;
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 600;
  color: #12284c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.hor-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e8eef2;
  margin-top: 25px;
}

/* PS History */

.stats-card {
  margin-bottom: 20px;
  border: none;
  background-color: none;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
}

.top-rounded {
  border-radius: 12px 12px 0 0 !important;
}

.bottom-rounded {
  border-radius: 0 0 12px 12px !important;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0px;
}

.alert-head {
  color: #155724;
  background-color: #fff;
  border-color: none;
}

.alert-scheduled {
  color: #155724;
  background-color: #eeeafd;
  border-color: none;
}

.alert-posted {
  color: #155724;
  background-color: #f3f5f7;
  border-color: none;
}

.alert-header {
  color: #155724;
  background-color: #f3f5f7;
  border-color: none;
}

.icon {
  margin: auto;
  display: block;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 4px 0px 10px;
}

.dot-scheduled {
  background-color: #a18ee7;
}

.dot-shared {
  background-color: #b4b6b7;
}

.dot-edit {
  background-color: #3b5072;
}

.dot-view {
  background-color: #59ce87;
}

.section-header {
  font-family: "roboto", sans-serif !important;
  font-size: 20px;
  width: 100%;
  padding: 0.25rem 0.5rem;
  clear: both;
  font-weight: 600;
  color: #2e3e58;
  vertical-align: middle;
}

.update-header {
  font-family: "roboto", sans-serif !important;
  font-size: 16px;
  color: #1d2838;
  font-weight: 400;
}

.update {
  font-family: "roboto", sans-serif !important;
  font-size: 14px;
  color: #1d2838;
  font-weight: 400;
}

.btn-edit {
  font-family: "roboto", sans-serif !important;
  font-size: 12px;
  background: #3b5072;
  font-weight: 200;
  color: #ffffff;
  width: 60px;
  height: 25px;
  vertical-align: middle;
  line-height: 12px;
}

.btn-view {
  font-family: "roboto", sans-serif !important;
  font-size: 12px;
  background: #59ce87;
  font-weight: 200;
  color: #ffffff;
  width: 60px;
  height: 25px;
  vertical-align: middle;
  line-height: 12px;
}

.section-sub-header {
  font-family: "roboto", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  color: #2e3e58;
  vertical-align: middle;
}

.table-box {
  background-color: #fff;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
}

/* TEST CSS */

.text-success {
  color: #02bc77 !important;
}

.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1;
}

.lnr {
  font-family: "roboto", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.text-info {
  color: #28c3d7 !important;
}

.text-danger {
  color: #d9534f !important;
}

.text-white {
  color: #ffffff !important;
}

.text-warning {
  color: #ffd950 !important;
}

.d-flex {
  flex-shrink: 1;
  display: flex !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-small {
  font-family: "roboto", sans-serif !important;
  font-size: 13px;
  font-weight: 300;
  color: #5f6060;
  text-align: left;
}

.text-large {
  font-family: "roboto", sans-serif !important;
  font-size: 42px;
  font-weight: 400;
  color: #3b5072;
}

.txt-lg-social-stats {
  font-family: "roboto", sans-serif !important;
  color: #1d2838;
  font-weight: 400;
  font-size: 30px;
}

.stats-box {
  background-color: #fff;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
  min-height: 100px;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.tw-stats-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #25b1e7;
  border-bottom: 0px;
  min-height: 50px;
}

.fb-stats-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #3b5e93;
  border-bottom: 0px;
  min-height: 50px;
}

.li-stats-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #497aae;
  border-bottom: 0px;
  min-height: 50px;
}

.border-0 {
  border: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.social-stats-row {
  height: 90px;
  background-color: #fff;
}

.vl2 {
  padding-top: 10px;
  padding-right: 20px;
  border-right: 1px solid #ccc;
}

.white {
  color: #fff;
}

.stats-pd {
  padding-top: 10px;
}

.stats-row {
  padding-bottom: 10px;
}

.tlb-pd {
  padding-left: 1px;
}

.tbl-format {
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
  min-height: 50px;
}

.lt-gray {
  color: #b4b6b7;
}

.icon-padding {
  margin-right: 5px;
}

.click-stats {
  color: #6bc4e8;
}

.media-stats {
  color: #59ce87;
}

/* PS Settings */

.users-header {
  font-family: "roboto", sans-serif !important;
  font-size: 16px;
  color: #1d2838;
  font-weight: 400;
}

.users-header-center {
  font-family: "roboto", sans-serif !important;
  font-size: 16px;
  color: #1d2838;
  font-weight: 400;
  text-align: center;
}

.users {
  font-family: "roboto", sans-serif !important;
  font-size: 14px;
  color: #1d2838;
  font-weight: 400;
}

.users-center {
  font-family: "roboto", sans-serif !important;
  font-size: 14px;
  color: #1d2838;
  font-weight: 400;
}

.btn-delete-sm {
  font-family: "roboto", sans-serif !important;
  font-size: 12px;
  background: #808080;
  font-weight: 200;
  color: #ffffff;
  width: 65px;
  height: 25px;
  vertical-align: middle;
  line-height: 12px;
  margin-left: 20px;
}

.settings-top {
  min-height: 250px;
}

.settings-bottom {
  min-height: 225px;
}

#add_url,
#add_url_linkedln {
  display: none;
  transition: display 1s ease-in;
}

#add_url.show-link,
#add_url_linkedln.show-link {
  display: block;
}

#image-url-link-twitter,
#image-url-link-linkedIn {
  cursor: pointer;
}

.stats-box .card-body .row .logo-circle-icon {
  border: 7px solid #b0d8e8;
  background-color: #6bc4e8;
  border-radius: 100%;
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 1434px) {
  .stats-box .card-body .row .logo-circle-icon {
    border: 3px solid #b0d8e8;

    width: 30px;
    height: 30px;
  }

  .txt-lg-social-stats {
    font-size: 20px;
  }
}

@media screen and (max-width: 1366px) {
  body .social-stats-row .col-4 .social-media-request-data,
  body .stats-box .col-3 .social-media-request-data {
    font-size: 18px !important;
    margin-top: 0.5rem;
  }
  .stats-box .text-small {
    font-size: 10px !important;
  }

  .txt-lg-social-stats {
    font-size: 20px;
  }
  .stats-box .card-body .social-stats-row .col-3 .text-small i {
    display: none !important;
  }

  .stats-box .card-body .social-stats-row .data-vl12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  body .social-stats-row .col-4 .social-media-request-data,
  body .stats-box .col-3 .social-media-request-data {
    font-size: 18px !important;
    margin-top: 0.5rem;
  }
  .stats-box .text-small i {
    display: none;
  }
  .stats-box .social-stats-row .text-small {
    font-size: 10px;
  }
  .stats-box .card-body .social-stats-row .col-3 .text-small i {
    display: none !important;
  }
}
