
body {
  background: #eee !important;
}

.wrapper {
  margin-top: 80px;
  margin-bottom: 1px;
}

.form-signin,.form-payment {
  max-width: 580px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  /*border-bottom-left-radius: 0;*/
  /*border-bottom-right-radius: 0;*/
}

.form-signin input[type="password"] {
  margin-bottom: 20px;
  /*border-top-left-radius: 0;*/
  /*border-top-right-radius: 0;*/
}


.form-signin-heading,.form-payment-heading {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: roboto, sans-serif;
  font-size: 30px;
  color: #2e3e58;
  font-weight: 400;
}

.form-signin .form-group, .form-payment .form-group {
  margin-bottom: 1rem !important;
}

.form-signin .btn-post {
  background: #58ce87;
  color: #fff;
}

.form-signin .btn, .form-payment .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-signin .btn:hover {
  opacity: 0.75;
}

.form-signin a {
  color: #2e3e58;
}

.form-signin a:hover {
  color: #6bc4e8;
}

.form-signin .cta-button {
  font-family: roboto, sans-serif;
  font-size: 16px;
  font-weight: medium;
  text-transform: uppercase;
  width: 112px;
  margin: 3px;
}

.form-signin .val-button {
  font-family: roboto, sans-serif;
  font-size: 16px;
  font-weight: medium;
  text-transform: uppercase;
  /*width: 225px;*/
  margin: 3px;
}

.form-signin .card-outline-inverse .card-header {
  background: #354765;
  border-color: #354765;
}

.form-signin .login-help {
  max-width: 580px;
  margin: 0 auto;
  background-color: transparent;
}

/* PW VALIDATION */

#pswd_info {
  color: #fff;
  left: 565px;
  position: absolute;
  top: -70px;
  background-color: #fff;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
  width: 350px;
}

#pswd_info h4 {
  background: #354765 none repeat scroll 0 0;
  display: block;
  font-family: roboto, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  padding: 17px 0;
  text-align: center;
  text-transform: uppercase;
  border-radius: 12px 12px 0 0;
}

#pswd_info ul {
  list-style: outside none none;
}

#pswd_info ul li {
  padding: 5px 15px;
}

.valid {
  background: rgba(0, 0, 0, 0) no-repeat scroll 2px 6px;
  color: green;
  line-height: 21px;
  padding-left: 22px;
}

.invalid {
  background: rgba(0, 0, 0, 0) no-repeat scroll 2px 6px;
  color: #2e3e58;
  line-height: 21px;
  padding-left: 22px;
}

/* Arrow */
#pswd_info::before {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 25px;
  left: -13px;
  margin-top: -12.5px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 25px;
}

/* ORDER PAGE */

.card {
  margin-bottom: 20px;
  border: none;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.table-box {
  background-color: #FFF;
  box-shadow: 2px 2px 20px 0px lightgrey;
  border-radius: 10px;
}

/* Radio Plans */

.frb-group {
  margin: 15px 0;
}

.frb ~ .frb {
  margin-top: 15px;
}

.frb input[type="radio"]:empty,
.frb input[type="checkbox"]:empty {
  display: none;
}

.frb input[type="radio"] ~ label:before,
.frb input[type="checkbox"] ~ label:before {
  font-family: FontAwesome;
  content: '\f096';
  position: absolute;
  top: 50%;
  margin-top: -11px;
  left: 15px;
  font-size: 22px;
}

.frb input[type="radio"]:checked ~ label:before,
.frb input[type="checkbox"]:checked ~ label:before {
  content: '\f046';
}

.frb input[type="radio"] ~ label,
.frb input[type="checkbox"] ~ label {
  position: relative;
  cursor: pointer;
  width: 100%;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: #f2f2f2;
}

.frb input[type="radio"]:checked ~ label,
.frb input[type="checkbox"]:checked ~ label {
  color: #fafafa;
}

.frb.frb-default input[type="radio"]:checked ~ label,
.frb.frb-default input[type="checkbox"]:checked ~ label {
  color: #333;
}

.frb.frb-ps input[type="radio"]:checked ~ label,
.frb.frb-pl input[type="checkbox"]:checked ~ label {
  background-color: #6bc4e8;
}

.frb.frb-plus input[type="radio"]:checked ~ label,
.frb.frb-plus input[type="checkbox"]:checked ~ label {
  background-color: #3B5072;
}

.frb input[type="radio"]:empty ~ label span,
.frb input[type="checkbox"]:empty ~ label span {
  display: inline-block;
}

.frb input[type="radio"]:empty ~ label span.frb-title,
.frb input[type="checkbox"]:empty ~ label span.frb-title {
  font-size: 16px;
  font-weight: 400;
  margin: 5px 2px 0px 50px;
}

.frb input[type="radio"]:empty ~ label span.frb-description,
.frb input[type="checkbox"]:empty ~ label span.frb-description {
  font-size: 16px;
  font-weight: 400;
  margin: 0px 2px 5px 50px;
}
